/*------------------------------------------------------------------
[ Global ]*/
@import url(//fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Sharp|Material+Icons+Round|Material+Icons+Two+Tone);
@import url("https://fonts.googleapis.com/css?family=Prompt:300,400,500|Sarabun:300,400,500&display=swap");
// $public_url: "/mt_supply";
$public_url: "";
@font-face {
  font-family: "CSPraJad";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), url("/asset/fonts/CSPraJad.otf") format("opentype");
}
@font-face {
  font-family: "CSPraJad-bold";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), url("/asset/fonts/CSPraJad-bold.otf") format("opentype");
}

body > div.root {
  height: 100%;
}
*,
h1,
h2,
h3,
h4,
h5,
h6,
// p,
a,
button {
  // margin: 0px;
  // padding: 0px;
  box-sizing: border-box;
  font-family: "Prompt", sans-serif;
}
p,
strong,
li,
ol {
  font-size: 16px;
  line-height: 1.4285em;
}

body {
  margin: 0px;
}
.header {
  font-family: "Prompt", sans-serif;
}
.header-responsive {
  @media (max-width: 767px) {
    font-size: 24px;
  }
}
.body-1 {
  font-family: "Sarabun", sans-serif;
}
.font-prompt {
  font-family: "Prompt", sans-serif !important;
}
.font-sarabun {
  font-family: "Sarabun", sans-serif !important;
  *:not(strong) {
    font-family: "Sarabun", sans-serif !important;
  }
  strong {
    font-family: "Sarabun", sans-serif;
  }
}

.caption {
  font-family: "Prompt", sans-serif;
  font-weight: lighter;
  color: #858585;
}
a {
  // color: unset;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
}
a:hover {
  color: #07d1bf !important;
  // filter: brightness(1.1);
  text-decoration: none;
}
a:focus {
  outline: none;
}
.height-fullscreen {
  height: 100vh;
}
.transfrom-translate-top-50 {
  transform: translateY(-50%);
}

div.logo-circle {
  border-radius: 1000px;
  height: 50px;
  width: 50px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

div.logo-circle-m {
  border-radius: 1000px;
  height: 75px;
  width: 75px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.anime-rotate-180 {
  transform: rotate(270deg);
  transition: transform 0.2s linear;
}
.anime-rotate-0 {
  transform: rotate(90deg);
  transition: transform 0.2s linear;
}

.pointer {
  cursor: pointer;
}

.rec-product {
  padding-top: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0.5rem 0;
}
.rec-product-show {
  padding-top: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0.5rem 0;
}
.shadow-mt {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
/*------------------------------------------------------------------
[ Button ]*/
.button-gradient {
  padding: 11px 0;
  width: 188px;
  color: white;
  border-style: none;
  background: linear-gradient(89.87deg, #0295c4 0%, #07d1bf 100%);
  transition: all 0.25s ease-in-out;
  background-size: 200% 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @extend .font-prompt;
  font-weight: lighter;
  &:hover {
    background-position: 100% 0;
  }
  &:focus {
    outline: none;
  }
  // @extend .bg-packagingportbutton;
}

.button-gradient-active {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  animation: activeBtn 0.3s forwards;
}
@keyframes activeBtn {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.button-light {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  &:hover {
    background-color: white;
    color: #0295c4 !important;
  }
}

.breadcrumb {
  background-color: white !important;
}
.breadcrumb-item {
  a {
    color: #323232;
  }
}
.active.breadcrumb-item {
  color: #07d1bf;
  &::before {
    content: ">";
    color: #323232;
  }
}
.min-height-100 {
  min-height: 100vh;
  max-height: inherit;
}
/*------------------------------------------------------------------ Navbar ------------------------------------------------------------------*/
.animate-nav-down {
  animation: key-nav-down 0.2s forwards;
}
.animate-nav-up {
  animation: key-nav-up 0.2s forwards;
}
@keyframes key-nav-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes key-nav-up {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
/*------------------------------------------------------------------ Menu ------------------------------------------------------------------*/
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><g id='menu_24px'><path id='icon/navigation/menu_24px' fill-rule='evenodd' clip-rule='evenodd' d='M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z' fill='white'/></g></svg>");
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-size: 100% 100%;
}
.navbar-toggler {
  background-color: #06c1c1;
  // height: 50px;
  padding-left: 5px;
  padding-right: 5px;
}
.active-menu {
  filter: brightness(1.1);
}
.active-menu > a::after {
  filter: brightness(1.1);
  color: white;
  content: "";
  position: absolute;
  border-bottom: 1.5px solid white;
  width: 72px;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.menu-hover {
  filter: brightness(1.1);
}
.menu-hover > a:hover::after {
  filter: brightness(1.1);
  color: white;
  content: "";
  position: absolute;
  border-bottom: 1.5px solid white;
  width: 72px;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.banner-img-change {
  padding: 0 325px;
  @media (max-width: 1650px) {
    padding: 0 180px;
  }
  @media (max-width: 1390px) {
    padding: 0 120px;
  }
  @media (max-width: 1670px) {
    padding: 0 30px;
  }
}
.nav-na12 {
  @media (max-width: 767px) {
    .nav-item {
      padding: 15px 0 !important;
    }
  }
}

/*------------------------------------------------------------------ swipper-Home ------------------------------------------------------------------*/
.bg-menu {
  background: linear-gradient(256.42deg, #06c5c0 0%, #0295c4 100%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bg-navbar {
  background: linear-gradient(185deg, #07d1bf 0%, #039ac4 100%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bg-blue {
  background: #0295c4;
}
.bg-green {
  background: #07d1bf;
}
.bg-packagingport {
  background: url("/asset/01-Home/0085.png") no-repeat;
  background-position: center center;
  height: 100%;
  max-height: 100%;
  background-size: cover;
}
.bg-packagingportbutton {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 0;
  width: 188px;
  color: white;
  border-style: none;
  background: linear-gradient(89.87deg, #0295c4 0%, #07d1bf 100%);
  transition: all 0.25s ease-in-out;
  background-size: 200% 100%;
  &:hover {
    background-position: 100% 0;
  }
}
.bg-gradient {
  background: linear-gradient(185deg, #07d1bf 0%, #039ac4 100%);
}
.h-450px {
  height: 450px;
}
.lineheight-40px {
  line-height: 40px;
}
div.swiper-wrapper {
  // padding: 0 1rem;
  div.swiper-slide.slide-banner {
    height: 400px;
  }

  div.swiper-slide.slide-client > div {
    margin: auto;
    height: 100px;
    width: 100px;
    border-radius: 500px;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  outline: none !important;
  background-image: none !important;
  width: unset !important;
}
.slick-center {
  padding: 0 1%;
}

.swiper-button-next.client,
.swiper-button-prev.client {
  color: #0295c4;
}
.swiper-button-disabled {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
/*------------------------------------------------------------------ Knowledge-Home ------------------------------------------------------------------*/
div.knowledge-img-head {
  display: block;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.rec-detail {
  padding-top: 100% !important;
}
.rec-detail > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/*------------------------------------------------------------------ Service ------------------------------------------------------------------*/
div.head-logo-service {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1000px;
  overflow: hidden;
}
div.service-list {
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

div.image-background-service {
  background-image: url("/asset/02-Service/0104.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 767px) {
    height: 100px;
  }
}

div.image-background-contact {
  background-image: url("/asset/06-Contact/0169.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 767px) {
    height: 100px;
  }
}
div.image-background-faq {
  background-image: url("/asset/07-FAQ/0169-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 767px) {
    height: 100px;
  }
}

.service-items > div.border {
  padding-top: 100%;
}
.service-items-detail {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
}
.service-items-detail > div {
  transform: translateY(-50%);
}

/*------------------------------------------------------------------ Portfolio ------------------------------------------------------------------*/
div.image-background-portfolio {
  background-image: url("/asset/04-Portfolio/0104.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 767px) {
    height: 100px;
  }
}

div.portfolio-items {
  padding-top: 70%;
}
.portfolio-items-detail {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 70%;
}

/*------------------------------------------------------------------ About Us ------------------------------------------------------------------*/
div.image-background-aboutus {
  background-image: url("/asset/05-About-Us/0282.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 767px) {
    height: 100px;
  }
}

.content-text {
  text-indent: 2.5rem;
  // padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  // p{
  //   display: inline-block;
  // }
}

/*------------------------------------------------------------------ Product ------------------------------------------------------------------*/
div.product-list {
  width: 100%;
  padding-top: 100%;
}
.product-list-detail {
  top: 50%;
}
.product-preview-left {
  overflow: hidden;
}
.image-background-product {
  background-image: url("/asset/03-TypeProduct/0104.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 767px) {
    height: 100px;
  }
}
.reps-product {
  overflow: hidden;
  @media (min-width: 1200px) {
    width: 350px;
    height: 233px;
  }
  @media (max-width: 1199px) {
    width: 380px;
    height: 247px;
  }
  @media (max-width: 991px) {
    width: 210px;
    height: 139px;
  }
  @media (max-width: 767px) {
    width: 240px;
    height: 159px;
  }
  @media (max-width: 575px) {
    height: 120px;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
/*------------------------------------------------------------------ Service ------------------------------------------------------------------*/
.form-contact {
  box-shadow: 0px 0px 10px #07d1bf;
  border-radius: 10px;
  padding: 68px;
  margin: 0 44px 44px 44px;
  @media (max-width: 991px) {
    padding: 20px;
  }
}
/*------------------------------------------------------------------ FAQ ------------------------------------------------------------------*/

/*------------------------------------------------------------------ News ------------------------------------------------------------------*/
.img-thumnail {
  border-radius: 5000px;
  height: 30px;
  width: 30px;
  overflow: hidden;
  border: 1px solid #07d1bf;
  margin-right: 5px;
  img {
    height: 100%;
  }
}

/*------------------------------------------------------------------ TEXT ------------------------------------------------------------------*/
.text-white {
  color: #ffffff !important;
}

.text-blue {
  color: #0295c4;
}

.text-green {
  color: #07d1bf;
}
.text-title-blog {
  word-wrap: break-word;
  line-height: 1.6;
  max-height: 3.85rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.text-descriptionmore {
  word-wrap: break-word;
  line-height: 1.7em;
  height: 3.85rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
/*------------------------------------------------------------------ Search Input ------------------------------------------------------------------*/
input.input-search {
  border: 2px solid #0295c4;
  border-radius: 500px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.54);
  height: 42px;
  &:focus {
    outline: none;
  }
  @media (max-width: 767px) {
    padding: 10px 0px 10px 55px !important;
  }
}

.icon-search-ele {
  left: 25px;
}

/*------------------------------------------------------------------ Input ------------------------------------------------------------------*/
.input-fire {
  border: 1px solid #0295c4;
  border-radius: 0px;
}
.social-content {
  div {
    width: 33.71px;
    height: 33.71px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    margin: 0 10px;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}

.ReactModal__Overlay {
  z-index: 9999999 !important;
}

.container-map {
  position: relative;
  cursor: pointer;
}

.image-map {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #bababa, $alpha: 0.4);
  i {
    color: white;
    font-size: 70px;
  }
}

.container-map:hover .image {
  opacity: 0.3;
}

.container-map:hover .middle {
  opacity: 1;
}

.table-detail-product {
  @media (min-width: 768px) {
    width: 50% !important;
  }
  th,
  td {
    border-color: #06c1c1 !important;
  }
  thead {
    background: linear-gradient(185deg, #07d1bf 0%, #039ac4 100%);
    color: white;
  }
}

/*------------------------------------------------------------------ Animation ------------------------------------------------------------------*/

.delay-0-5s {
  animation-delay: 0.5s;
}

.delay-0-1s {
  animation-delay: 0.1s;
}

/*------------------------------------------------------------------ ECT. ------------------------------------------------------------------*/

.img-logo {
  height: 100px;
}

.img-about-us {
  width: 700px;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.button-action-menu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  a,li {
    background-color: #0295c4;  
  }
}
