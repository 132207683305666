div.blog-style-for-worldpress {
  color: #323232;
  max-width: 800px;
  .modal-dialog {
    min-height: calc(100vh - 60px);

    display: flex;

    flex-direction: column;

    justify-content: center;

    overflow: auto;

    z-index: 99999;
  }

  @media (max-width: 768px) {
    .modal-dialog {
      min-height: calc(100vh - 20px);
    }
  }

  #wpadminbar,
  #adminmenuwrap {
    z-index: 9999;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
  }

  .alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }

  img{
    max-width: 100%;
    height: auto;
  }

  /*---------------------
      Helper CSS
    -----------------------*/

  .section-title {
    text-align: left;
  }

  .section-title h2 {
    font-size: 36px;
  }

  .set-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .spad {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .text-white h1,
  .text-white h2,
  .text-white h3,
  .text-white h4,
  .text-white h5,
  .text-white h6,
  .text-white p,
  .text-white span,
  .text-white li,
  .text-white a {
    color: #fff;
  }

  .overflow-hide {
    overflow: hidden;
  }

  /*---------------------
      Commom elements
    -----------------------*/

  /* buttons */

  .site-btn {
    display: inline-block;
    border: none;
    font-size: 14px;
    font-weight: 600;
    min-width: 167px;
    padding: 17px 0px;
    background: #2e3192;
    color: #fff;
    line-height: normal;
    cursor: pointer;
    text-align: center;
  }

  .site-btn:hover {
    filter: brightness(0.9);
    color: #fff;
  }

  .site-btn.sb-white {
    background: #fff;
    color: #111111;
  }

  .site-btn.sb-line {
    background: transparent;
    color: #fff;
    -webkit-box-shadow: inset 0 0 0 1px #fff;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .site-btn.sb-dark {
    background: #413a3a;
  }

  .site-btn.sb-dark.sb-line {
    background-color: transparent;
    color: #222222;
    -webkit-box-shadow: inset 0 0 0 1px #222222;
    box-shadow: inset 0 0 0 1px #222222;
  }

  .site-btn.sb-dark:hover {
    background-color: #2e3192;
    color: #ffffff;
    -webkit-box-shadow: inset 0 0 0 1px #2e3192;
    box-shadow: inset 0 0 0 1px #2e3192;
  }

  .btn-disable:hover {
    color: unset !important;
    background-color: #efefef !important;
    cursor: not-allowed;
  }

  .elements-section {
    padding-top: 100px;
  }

  .el-title {
    margin-bottom: 75px;
  }

  .element {
    margin-bottom: 100px;
  }

  .element:last-child {
    margin-bottom: 0;
  }

  /*------------------
      Header section
    ---------------------*/

  .header-top {
    padding: 10px 0;
  }

  .site-logo {
    display: inline-block;
  }

  .header-search-form {
    width: 100%;
    position: relative;
    padding: 0 10px;
  }

  .header-search-form button {
    position: absolute;
    height: 100%;
    right: 18px;
    top: 0;
    font-size: 26px;
    color: #000;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  .user-panel .up-item {
    display: inline-block;
    font-size: 14px;
  }

  .user-panel .up-item i {
    font-size: 22px;
  }

  .user-panel .up-item a {
    font-size: 14px;
    color: #000;
  }

  .user-panel .up-item:first-child {
    margin-right: 29px;
  }

  .shopping-card {
    display: inline-block;
    position: relative;
  }

  .shopping-card span {
    position: absolute;
    top: -8px;
    left: 100%;
    height: 20px;
    min-width: 20px;
    color: #fff;
    font-size: 12px;
    background: #2e3192;
    text-align: center;
    padding-top: 1px;
    border-radius: 30px;
    margin-left: -10px;
  }

  .slicknav_nav .active {
    color: #2e3192;
    background: linear-gradient(
      90deg,
      rgba(65, 64, 66, 1) 0%,
      rgba(147, 149, 152, 1) 15%,
      rgba(255, 255, 255, 1) 35%,
      rgba(209, 211, 212, 1) 50%,
      rgba(255, 255, 255, 1) 65%,
      rgba(147, 149, 152, 1) 85%,
      rgba(65, 64, 66, 1) 100%
    );
  }
  .slicknav_nav .active a {
    color: #2e3192;
  }

  .main-navbar {
    background: #2e3192;
  }

  .slicknav_menu {
    display: none;
  }

  .main-menu {
    list-style: none;
  }

  .main-menu li {
    display: inline-block;
    position: relative;
  }

  .main-menu .active a {
    color: #2e3192;
  }

  .main-menu li a {
    display: inline-block;
    font-size: 16px;
    color: #ffffff;
    margin-right: 50px;
    line-height: 1;
    padding: 17px 0;
    position: relative;
  }

  .main-menu .active {
    color: #2e3192;
    background: linear-gradient(
      90deg,
      rgba(65, 64, 66, 1) 0%,
      rgba(147, 149, 152, 1) 15%,
      rgba(255, 255, 255, 1) 35%,
      rgba(209, 211, 212, 1) 50%,
      rgba(255, 255, 255, 1) 65%,
      rgba(147, 149, 152, 1) 85%,
      rgba(65, 64, 66, 1) 100%
    );
  }

  .main-menu li a .new {
    position: absolute;
    top: -8px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background: #2e3192;
    line-height: 1;
    left: calc(50% - 21px);
    padding: 5px 9px 1px;
    border-radius: 15px;
    width: 42px;
  }

  .main-menu li:hover .sub-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }

  .main-menu li:hover > a {
    color: #2e3192;
    background: linear-gradient(
      90deg,
      rgba(65, 64, 66, 1) 0%,
      rgba(147, 149, 152, 1) 15%,
      rgba(255, 255, 255, 1) 35%,
      rgba(209, 211, 212, 1) 50%,
      rgba(255, 255, 255, 1) 65%,
      rgba(147, 149, 152, 1) 85%,
      rgba(65, 64, 66, 1) 100%
    );
  }

  .main-menu .sub-menu {
    position: absolute;
    list-style: none;
    width: 220px;
    left: 0;
    top: 100%;
    padding: 20px 0;
    visibility: hidden;
    opacity: 0;
    margin-top: 50px;
    background: #fff;
    z-index: 99;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
  }

  .main-menu .sub-menu li {
    display: block;
  }

  .main-menu .sub-menu li a {
    display: block;
    color: #000;
    margin-right: 0;
    padding: 8px 20px;
  }

  .main-menu .sub-menu li a:hover {
    color: #2e3192;
  }

  .nav-switch {
    display: none;
  }

  /* ----------------
      Features
    ---------------------*/

  .border-features {
    border-color: #2e3192 !important;
  }

  .border-features span {
    font-size: 12px;
  }

  .hero-section {
    padding-bottom: 54px;
  }

  .hero-slider .hs-item {
    position: relative;
    height: 600px;
  }

  .hero-slider .hs-item span {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 3px;
    margin-bottom: 5px;
    display: block;
    position: relative;
    top: 50px;
    opacity: 0;
  }

  .hero-slider .hs-item h2 {
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    top: 100px;
    opacity: 0;
  }

  .hero-slider .hs-item p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 35px;
    position: relative;
    top: 100px;
    opacity: 0;
  }

  .hero-slider .hs-item .site-btn {
    position: relative;
    margin-bottom: 35px;
    top: 50px;
    opacity: 0;
  }

  .hero-slider .hs-item h5 {
    position: relative;
    top: 100px;
    opacity: 0;
  }

  .hero-slider .hs-item .sb-line {
    margin-right: 5px;
  }

  .hero-slider .hs-item .container {
    position: relative;
    /*padding-top: 120px;*/
  }

  .hero-slider .slider-nav-warp {
    max-width: 1145px;
    bottom: 0;
    margin: -78px auto 0;
  }

  .hero-slider .slider-nav {
    display: inline-block;
    padding: 0 38px;
    position: relative;
  }

  .hero-slider .owl-dots {
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 9px;
  }

  .hero-slider .owl-dots .owl-dot {
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 15px;
    margin-right: 10px;
    opacity: 0.25;
  }

  .hero-slider .owl-dots .owl-dot.active {
    opacity: 1;
  }

  .hero-slider .owl-dots .owl-dot:last-child {
    margin-right: 0;
  }

  .hero-slider .owl-nav button.owl-next,
  .hero-slider .owl-nav button.owl-prev {
    font-size: 27px;
    position: absolute;
    color: #fff;
    opacity: 0.5;
    bottom: -20px;
    z-index: 0;
  }

  .hero-slider .owl-nav button.owl-next {
    right: 0;
  }

  .hero-slider .owl-nav button.owl-prev {
    left: 0;
  }

  .hero-slider .owl-item.active .hs-item h2,
  .hero-slider .owl-item.active .hs-item span,
  .hero-slider .owl-item.active .hs-item p,
  .hero-slider .owl-item.active .hs-item .site-btn,
  .hero-slider .owl-item.active .hs-item h5 {
    top: 0;
    opacity: 1;
  }

  .hero-slider .owl-item.active .hs-item span {
    -webkit-transition: all 0.5s ease 0.2s;
    -o-transition: all 0.5s ease 0.2s;
    transition: all 0.5s ease 0.2s;
  }

  .hero-slider .owl-item.active .hs-item h2 {
    -webkit-transition: all 0.5s ease 0.4s;
    -o-transition: all 0.5s ease 0.4s;
    transition: all 0.5s ease 0.4s;
  }

  .hero-slider .owl-item.active .hs-item p {
    -webkit-transition: all 0.5s ease 0.6s;
    -o-transition: all 0.5s ease 0.6s;
    transition: all 0.5s ease 0.6s;
  }

  .hero-slider .owl-item.active .hs-item .site-btn {
    -webkit-transition: all 0.5s ease 0.8s;
    -webkit-transition: all 0.5s ease 0.8s;
    -o-transition: all 0.5s ease 0.8s;
    transition: all 0.5s ease 0.8s;
  }

  .hero-slider .owl-item.active .hs-item h5 {
    -webkit-transition: all 0.5s ease 1s;
    -webkit-transition: all 0.5s ease 1s;
    -o-transition: all 0.5s ease 1s;
    transition: all 0.5s ease 1s;
  }

  .slide-num-holder {
    float: right;
    z-index: 1;
    color: #fff;
    position: relative;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    margin-top: -22px;
  }

  .slide-num-holder span:first-child {
    margin-right: 41px;
  }

  .slide-num-holder:after {
    position: absolute;
    content: "";
    height: 30px;
    width: 1px;
    background: #fff;
    left: 50%;
    top: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  /* ------------------
      Features section
    ---------------------*/

  .main-feature {
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .feature {
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background: #f8f8f8;
    height: 100%;
    border: 1px solid white;
  }
  /* 
    .feature:nth-child(2) {
        background: #2e3192;
    }
    
    .feature:nth-child(2) i,
    .feature:nth-child(2) h5 {
        color: #fff;
    } */

  .feature {
    background: #2e3192;
  }

  .feature i,
  .feature h5 {
    color: #fff;
  }

  /* ----------------------
      Latest product section
    ------------------------*/

  .owl-nav {
    z-index: 1;
  }
  .owl-stage-outer {
    z-index: 2;
  }

  .product-slider .owl-nav {
    position: absolute;
    top: calc(50% - 60px);
    width: 100%;
    left: 0;
  }

  .product-slider .owl-nav button.owl-next,
  .product-slider .owl-nav button.owl-prev {
    color: #a4a4a4;
    font-size: 42px;
    position: relative;
  }

  .product-slider .owl-nav button.owl-next {
    float: right;
    right: -92px;
  }

  .product-slider .owl-nav button.owl-prev {
    float: left;
    left: -92px;
  }

  .product-item .pi-pic {
    position: relative;
    display: block;
    border: 5px solid transparent;
    background-image: linear-gradient(
      90deg,
      rgba(65, 64, 66, 1) 0%,
      rgba(147, 149, 152, 1) 15%,
      rgba(255, 255, 255, 1) 35%,
      rgba(209, 211, 212, 1) 50%,
      rgba(255, 255, 255, 1) 65%,
      rgba(147, 149, 152, 1) 85%,
      rgba(65, 64, 66, 1) 100%
    );
    border-image-slice: 1;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .product-item .pi-name .product-name {
    height: 30px;
    line-height: 1.2em;
    overflow: hidden;
  }

  .product-item .tag-new,
  .product-item .tag-sale {
    position: absolute;
    right: 16px;
    top: 14px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background: red;
    line-height: 1;
    padding: 6px 0px;
    border-radius: 15px;
    min-width: 55px;
    z-index: 1;
    text-align: center;
  }

  .product-item .tag-new {
    background: #2e3192;
  }

  .product-item .pi-links {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 18px;
    z-index: 9;
    padding-right: 15px;
    text-align: right;
  }

  .product-item .pi-links div {
    display: inline-table;
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 60px;
    font-size: 18px;
    line-height: 18px;
    padding-top: 9px;
    overflow: hidden;
    color: #000;
    position: relative;
    -webkit-box-shadow: 1px 0 32px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 0 32px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    text-align: center;
  }

  .product-item .pi-links div i {
    display: inline-block;
    color: #000;
  }

  .product-item .pi-links div.add-card {
    padding-top: 8px;
  }

  .product-item .pi-links div.add-card span {
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    right: 19px;
    top: 20px;
    opacity: 0;
  }

  .product-item .pi-links div.add-card:hover {
    width: 130px;
    padding: 8px 18px 0;
    text-align: left;
  }

  .product-item .pi-links div.add-card:hover span {
    opacity: 1;
    top: 10px;
    -webkit-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
  }

  /* -----------------------
      Product filter section
    -------------------------*/

  .product-filter-section {
    padding-bottom: 70px;
  }

  .product-filter-section .section-title {
    margin-bottom: 30px;
  }

  .product-filter-menu {
    list-style: none;
    margin: 0 -10px;
    padding-bottom: 15px;
  }

  .product-filter-menu li {
    margin: 0 10px 10px;
    display: inline-block;
  }

  .product-filter-menu li a {
    color: #111111;
    font-size: 12px;
    font-weight: 700;
    background: #ebebeb;
    display: block;
    width: 100%;
    padding: 10px 34px;
    border-radius: 31px;
  }

  /* ----------------
      Banner section
    ---------------------*/

  .banner {
    padding: 50px 34px 47px;
    position: relative;
    margin-bottom: 70px;
  }

  .banner span {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 3px;
    display: block;
  }

  .banner h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #282828;
  }

  .text-responsive{
    font-size: 18px;
    @media (max-width:376px) {
      font-size: 16px;
    }
  }

  /* ----------------
      Footer section
    ---------------------*/

  .footer-section {
    background: #282828;
    padding-top: 60px;
  }

  .footer-logo {
    padding-bottom: 60px;
  }

  .footer-widget {
    margin-bottom: 70px;
    overflow: hidden;
  }

  .footer-widget h2 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 45px;
  }

  .footer-widget p {
    color: #8f8f8f;
  }

  .footer-widget.about-widget p {
    margin-bottom: 50px;
    letter-spacing: -0.01em;
  }

  .footer-widget ul {
    list-style: none;
    float: left;
    margin-right: 37px;
  }

  .footer-widget ul:last-child {
    margin-right: 0;
  }

  .footer-widget ul li a {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    color: #8f8f8f;
    margin-bottom: 6px;
  }

  .footer-widget ul li a:after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    left: 0;
    top: 8px;
    border: 1px solid #ec105a;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .footer-widget ul li a:hover {
    color: #fff;
  }

  .footer-widget ul li a:hover:after {
    width: 7px;
    height: 7px;
    top: 6px;
    background: #ec105a;
  }

  .fw-latest-post-widget .lp-item {
    margin-bottom: 30px;
    display: block;
    overflow: hidden;
  }

  .fw-latest-post-widget .lp-thumb {
    width: 64px;
    height: 64px;
    float: left;
    margin-right: 22px;
  }

  .fw-latest-post-widget .lp-content {
    overflow: hidden;
    padding-top: 2px;
  }

  .fw-latest-post-widget .lp-content h6 {
    font-size: 14px;
    font-weight: 700;
    opacity: 0.25;
    color: #717171;
    margin-bottom: 1px;
  }

  .fw-latest-post-widget .lp-content span {
    display: block;
    font-size: 12px;
    color: #8f8f8f;
    margin-bottom: 4px;
  }

  .fw-latest-post-widget .lp-content .readmore {
    font-size: 12px;
    color: #2e3192;
  }

  .contact-widget .con-info span {
    float: left;
    color: #2e3192;
    padding-right: 15px;
    overflow: hidden;
  }

  .social-links-warp {
    border-top: 2px solid #3b3535;
    padding: 46px 0;
  }

  .social-links a {
    margin-right: 60px;
    display: inline-block;
  }

  .social-links a:last-child {
    margin-right: 0;
  }

  .social-links a i {
    font-size: 30px;
    color: #d7d7d7;
    float: left;
    margin-right: 19px;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .social-links a span {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    color: #9f9fa0;
    padding-top: 10px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .social-links a.instagram:hover i {
    color: #8a3ab9;
  }

  .social-links a.google-plus:hover i {
    color: #e04b37;
  }

  .social-links a.twitter:hover i {
    color: #5abed6;
  }

  .social-links a.pinterest:hover i {
    color: #cd212d;
  }

  .social-links a.linkedin:hover i {
    color: #0077b5;
  }

  .social-links a.facebook:hover i {
    color: #3b5998;
  }

  .social-links a.twitter:hover i {
    color: #5abed6;
  }

  .social-links a.youtube:hover i {
    color: #d12227;
  }

  .social-links a.tumblr:hover i {
    color: #37475e;
  }

  .social-links a.line:hover i {
    color: #00c300;
  }

  .social-links a:hover span {
    color: #fff;
  }

  /* --------------
      Other Pages
    ------------------*/

  .page-top-info {
    background: #f8f7f7;
    padding: 40px 0;
  }

  .page-top-info h4 {
    color: #414141;
    font-weight: 700;
  }

  .site-pagination {
    font-size: 13px;
    font-weight: 600;
    color: #888888;
  }

  .site-pagination a {
    display: inline-block;
    font-size: 13px;
    color: #888888;
  }

  .site-pagination a:hover {
    color: #414141;
  }

  .site-pagination .active {
    color: #414141;
  }

  /* --------------
      Category page
    ------------------*/

  .filter-widget {
    margin-bottom: 50px;
  }

  .filter-widget .fw-title {
    font-size: 18px;
    font-weight: 700;
    color: #414141;
    margin-bottom: 20px;
  }

  .category-menu {
    list-style: none;
  }

  .category-menu li div {
    display: block;
    position: relative;
    font-size: 12px;
    color: #414141;
    border-bottom: 1px solid #ebebeb;
    padding: 9px 0 5px 20px;
  }

  .category-menu li div span {
    float: right;
  }

  .category-menu li div:after {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    left: 0;
    top: 13px;
    border: 1px solid #2e3192;
    border-radius: 50%;
  }

  .category-menu li div:hover {
    color: #2e3192;
  }

  .category-menu li div:hover:after {
    background: #2e3192;
  }

  .category-menu li div:last-child div {
    margin-bottom: 0;
  }

  .category-menu li .sub-menu {
    list-style: none;
    overflow: hidden;
    height: 0;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    opacity: 0;
    -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.4s, -webkit-transform 0.4s;
    -o-transition: transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
  }

  .category-menu li .sub-menu li {
    padding-left: 20px;
  }

  .category-menu li .sub-menu li div {
    padding-left: 20px;
  }

  .category-menu li:hover > div {
    color: #2e3192;
  }

  .category-menu li.active > .sub-menu {
    display: block;
    height: auto;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }

  .fw-color-choose,
  .fw-size-choose {
    border-bottom: 2px solid #ebebeb;
    padding-bottom: 40px;
    margin-bottom: 50px;
  }

  .fw-color-choose .cs-item {
    display: inline-block;
    position: relative;
    margin-right: 14px;
  }

  .fw-color-choose .cs-item:last-child {
    margin-right: 0;
  }

  .fw-color-choose label {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    background: #333;
    position: relative;
    cursor: pointer;
  }

  .fw-color-choose label.cs-gray {
    background: #d7d7d7;
  }

  .fw-color-choose label.cs-orange {
    background: #6f91ff;
  }

  .fw-color-choose label.cs-yollow {
    background: #6f91ff;
  }

  .fw-color-choose label.cs-green {
    background: #8fc99c;
  }

  .fw-color-choose label.cs-purple {
    background: #bc83b1;
  }

  .fw-color-choose label.cs-blue {
    background: #9ee7f4;
  }

  .fw-color-choose label span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 45px;
    font-size: 11px;
    color: #414141;
  }

  .fw-size-choose .sc-item {
    display: inline-block;
    position: relative;
    margin-right: 5px;
  }

  .fw-size-choose label {
    display: inline-block;
    height: 30px;
    min-width: 30px;
    text-align: center;
    font-size: 14px;
    color: #414141;
    font-weight: 500;
    cursor: pointer;
    border-radius: 50px;
    padding: 7px 6px 0;
  }

  /* --------------
      Product page
    ------------------*/

  .product-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .back-link {
    padding-bottom: 50px;
  }

  .back-link a {
    font-size: 14px;
    color: #414141;
  }

  .product-pic-zoom {
    margin-bottom: 35px;
  }

  .product-thumbs-track {
    width: 1200px;
  }

  .product-thumbs .pt {
    width: 116px;
    height: 116px;
    float: left;
    margin-right: 31px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  .product-thumbs .pt:last-child {
    margin-right: 0;
  }

  .product-thumbs .pt.active:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 2px solid #2e3192;
    z-index: 1;
  }

  .product-details .p-title {
    font-size: 18px;
    font-weight: 700;
    color: #414141;
    margin-bottom: 18px;
  }

  .product-details .p-price {
    font-size: 24px;
    color: #2e3192;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .product-details .p-review {
    margin-bottom: 30px;
  }

  .product-details .p-review a {
    color: #414141;
    font-size: 14px;
    margin-right: 12px;
    margin-left: 12px;
  }

  .product-details .p-review a:first-child {
    margin-left: 0;
  }

  .product-details .fw-size-choose {
    border-bottom: none;
    margin-bottom: 30px;
    padding-bottom: 0;
  }

  .product-details .fw-size-choose p {
    float: left;
    margin-right: 38px;
    font-weight: 700;
    color: #414141;
    padding-top: 10px;
    margin-bottom: 0;
  }

  .product-details .fw-size-choose label {
    width: 33px;
    height: 33px;
    font-size: 12px;
    border: 2px solid #414141;
  }

  .product-details .fw-size-choose .disable label {
    border: 2px solid #e1e1e1;
    color: #cacaca;
  }

  .product-details .site-btn {
    min-width: 190px;
  }

  .product-details .social-sharing {
    padding-top: 50px;
  }

  .product-details .social-sharing a {
    color: #d7d7d7;
    margin-right: 23px;
    font-size: 14px;
  }

  .product-details .social-sharing a:hover {
    color: #414141;
  }

  .quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .quantity p {
    float: left;
    margin-right: 15px;
    font-weight: 700;
    color: #414141;
    padding-top: 10px;
    margin-bottom: 0;
  }

  .quantity .pro-qty {
    width: 94px;
    height: 36px;
    border: 1px solid #ddd;
    padding: 0 15px;
    border-radius: 40px;
    float: left;
  }

  .quantity .pro-qty .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    color: #404040;
  }

  .related-product-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .related-product-section .section-title h5 {
    margin-bottom: 30px;
  }

  .related-video-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .related-video-section .section-title h5 {
    margin-bottom: 30px;
  }
  /* ----------------
      Cart page
    ---------------------*/

  .scrollbar {
    margin: 80px auto 0;
    width: 100%;
    height: 7px;
    line-height: 0;
    background: #ececec;
    overflow: hidden;
  }

  .scrollbar .handle {
    width: 100px;
    height: 100%;
    background: #fff;
    cursor: pointer;
  }

  .scrollbar .handle .mousearea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7px;
    background: #b09d81;
    border-radius: 30px;
  }

  .card-right .site-btn {
    margin-bottom: 14px;
    width: 100%;
    min-height: 57px;
    padding: 23px 47px;
  }

  .promo-code-form {
    position: relative;
    margin-bottom: 14px;
  }

  .promo-code-form input {
    width: 100%;
    height: 58px;
    border: 2px solid #f0f0f0;
    padding-left: 24px;
    padding-right: 100px;
    font-size: 16px;
    border-radius: 80px;
  }

  .promo-code-form button {
    position: absolute;
    right: 24px;
    top: 0;
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: #2e3192;
    cursor: pointer;
  }

  /* ----------------
      Checkout Page
    ---------------------*/

  .checkout-form .cf-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    background-color: rgba(34, 157, 136, 0.1);
    padding: 15px;
    margin-bottom: 40px;
  }

  .checkout-form p {
    font-size: 16px;
    color: #414141;
  }

  .checkout-form h4 {
    font-size: 18px;
    color: #414141;
  }

  .checkout-form input {
    width: 100%;
    height: 44px;
    border: none;
    padding: 0 10px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .checkout-form textarea {
    width: 100%;
    height: 150px;
    border: none;
    padding: 20px 10px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .checkout-form input:focus,
  .checkout-form textarea:focus {
    border-bottom: 2px solid #2e3192;
  }

  .checkout-form .address-inputs {
    margin-bottom: 54px;
  }

  .checkout-cart {
    background: #f7f7f7;
    padding: 40px 24px 30px;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
  }

  .checkout-cart h3 {
    margin-bottom: 30px;
  }

  .checkout-cart .product-list {
    list-style: none;
  }

  .checkout-cart .product-list li {
    overflow: hidden;
    display: block;
    margin-bottom: 10px;
  }

  .checkout-cart .product-list .pl-thumb {
    float: left;
    overflow: hidden;
    margin-right: 22px;
    width: 75px;
  }

  .checkout-cart .product-list .pl-thumb img {
    min-width: 100%;
  }

  .checkout-cart .price-list {
    padding-left: 5px;
    padding-right: 5px;
    list-style: none;
  }

  .checkout-cart .price-list li {
    font-size: 15px;
    color: #414141;
    margin-bottom: 10px;
  }

  .checkout-cart .price-list li span {
    float: right;
    width: 80px;
    text-align: left;
  }

  .checkout-cart .price-list li.total {
    padding-top: 35px;
    font-weight: 700;
  }

  /* ----------------
      Contact Page
    ---------------------*/

  .contact-section {
    padding-top: 80px;
    padding-bottom: 70px;
    position: relative;
  }

  .contact-info h3 {
    margin-bottom: 50px;
  }

  .contact-social {
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 85px;
    padding-top: 20px;
  }

  .contact-social a {
    display: -ms-inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 32px;
    height: 32px;
    background: #f0f0f0;
    color: #414141;
    font-size: 14px;
    border-radius: 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 12px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .contact-social a:hover {
    color: #fff;
    background: #2e3192;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    height: 44px;
    border: none;
    padding: 0 18px;
    background: #f0f0f0;
    border-radius: 40px;
    margin-bottom: 17px;
    font-size: 14px;
  }

  .contact-form textarea {
    padding-top: 16px;
    border-radius: 18px;
    height: 175px;
    margin-bottom: 32px;
  }

  .map {
    position: absolute;
    width: calc(50% - 15px);
    height: 100%;
    right: 0;
    top: 0;
    background: #ddd;
  }

  .map iframe {
    width: 100%;
    height: 100%;
  }

  /* ----------------
      Responsive
    ---------------------*/

  @media (min-width: 1200px) {
    .container {
      max-width: 1175px;
    }
  }

  @media (max-width: 1350px) {
    .product-slider .owl-nav {
      position: relative;
      left: 0;
      top: 0;
      text-align: center;
      padding-top: 20px;
    }
    .product-slider .owl-nav button.owl-prev,
    .product-slider .owl-nav button.owl-next {
      float: none;
      left: 0;
      right: 0;
      margin: 0 10px;
    }
  }

  /* Medium screen : 992px. */

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-slider .slider-nav-warp {
      max-width: 930px;
    }
    .footer-widget ul {
      margin-right: 5px;
    }
    .social-links a {
      margin-right: 20px;
    }
  }

  /* Tablet :768px. */

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .site-logo {
      margin-bottom: 20px;
    }
    .header-search-form {
      margin-bottom: 15px;
    }
    .user-panel {
      text-align: center;
    }
    .main-menu {
      text-align: center;
    }
    .sub-menu {
      text-align: left;
    }
    .main-menu li a {
      margin-right: 30px;
    }
    .hero-slider .slider-nav-warp {
      max-width: 690px;
    }
    .feature h2 {
      font-size: 18px;
    }
    .product-filter-menu {
      text-align: center;
    }
    .product-filter-menu li {
      margin: 0 5px 10px;
    }
    .social-links {
      text-align: center;
    }
    .social-links a {
      margin-right: 20px;
    }
    .social-links a span {
      display: none;
    }
    .map {
      position: relative;
      width: 100%;
      background: #ddd;
      height: 400px;
      margin-top: 70px;
    }
  }

  /* Large Mobile :480px. */

  @media only screen and (max-width: 767px) {
    .site-logo {
      margin-bottom: 20px;
    }
    .header-search-form {
      margin-bottom: 15px;
    }
    .user-panel {
      text-align: center;
    }
    .main-menu {
      display: none;
    }
    .slicknav_btn {
      background-color: #565656;
    }
    .slicknav_menu {
      background: #2e3192;
      display: block;
    }
    .slicknav_menu .new {
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      background: #2e3192;
      line-height: 1;
      padding: 5px 9px 1px;
      border-radius: 15px;
      width: 42px;
      margin-left: 5px;
    }
    .hero-slider .slider-nav-warp {
      max-width: 510px;
    }
    .hero-slider .hs-item h2 {
      font-size: 50px;
    }
    .product-filter-menu {
      text-align: center;
    }
    .product-filter-menu li {
      margin: 0 2px 10px;
    }
    .footer-widget ul {
      margin-right: 25px;
    }
    .social-links {
      text-align: center;
    }
    .social-links a {
      margin-right: 15px;
    }
    .social-links a span {
      display: none;
    }
    .map {
      position: relative;
      width: 100%;
      background: #ddd;
      height: 400px;
      margin-top: 70px;
    }
  }

  /* Medium Mobile :480px. */

  @media only screen and (min-width: 576px) and (max-width: 766px) {
    .hero-slider .slider-nav-warp {
      padding: 0 15px;
    }
    .banner .tag-new {
      position: relative;
      display: inline-block;
      margin-bottom: 18px;
      right: 0;
      top: 0;
    }
  }

  /* Small Mobile :320px. */

  @media only screen and (max-width: 479px) {
    .hero-slider .slider-nav-warp {
      max-width: 510px;
      padding: 0 15px;
    }
    .hero-slider .hs-item h2 {
      font-size: 35px;
    }
    .hero-slider .hs-item .sb-line {
      margin-bottom: 15px;
    }
    .section-title h2 {
      font-size: 28px;
    }
    .feature h2 {
      font-size: 18px;
    }
    .banner .tag-new {
      position: relative;
      display: inline-block;
      margin-bottom: 18px;
      right: 0;
      top: 0;
    }
    .social-links {
      text-align: center;
    }
    .social-links a i {
      font-size: 20px;
      margin-right: 0;
    }
    .social-links a span {
      display: none;
    }
  }

  /*============ blog_left_sidebar css ==============*/
  .blog_item {
    margin-bottom: 40px;
  }

  .blog_info {
    padding-top: 30px;
  }
  .blog_info .share a i {
    color: #777777;
  }
  .blog_info .post_tag {
    padding-bottom: 10px;
  }
  .blog_info .post_tag a {
    color: #222222;
  }
  .blog_info .post_tag a:hover {
    color: #2e3192;
  }
  .blog_info .post_tag a.active {
    color: #2e3192;
  }
  .blog_info .blog_meta li {
    font-size: 13px;
    color: #777777;
    vertical-align: middle;
    padding-bottom: 8px;
    padding-left: 20px;
    display: inline-block;
  }
  .blog_info .blog_meta li i {
    color: #222222;
    padding-left: 15px;
    vertical-align: middle;
  }

  .blog_post img {
    max-width: 100%;
  }

  .blog_details {
    padding-top: 20px;
  }
  .blog_details h2 {
    font-size: 24px;
    line-height: 36px;
    color: #222222;
    font-weight: 600;
    transition: all 0.3s linear;
  }
  .blog_details h2:hover {
    color: #2e3192;
  }
  .blog_details p {
    margin-bottom: 26px;
  }

  .view_btn {
    font-size: 14px;
    line-height: 36px;
    display: inline-block;
    color: #222222;
    font-weight: 500;
    padding: 0px 30px;
    background: #fff;
  }

  .blog_right_sidebar {
    background: #f7f7f7;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
    padding: 30px;
  }
  .blog_right_sidebar .widget_title {
    font-size: 18px;
    line-height: 25px;
    background: #2e3192;
    text-align: center;
    color: #fff;
    padding: 8px 0px;
    margin-bottom: 30px;
  }
  .blog_right_sidebar .search_widget .input-group .form-control {
    font-size: 14px;
    line-height: 29px;
    border: 0px;
    width: 100%;
    font-weight: 300;
    color: #fff;
    padding-left: 20px;
    border-radius: 45px;
    z-index: 0;
    background: #2e3192;
  }
  .blog_right_sidebar .search_widget .input-group .form-control.placeholder {
    color: #fff;
  }
  .blog_right_sidebar .search_widget .input-group .form-control:-moz-placeholder {
    color: #fff;
  }
  .blog_right_sidebar .search_widget .input-group .form-control::-moz-placeholder {
    color: #fff;
  }
  .blog_right_sidebar .search_widget .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
  .blog_right_sidebar .search_widget .input-group .form-control:focus {
    box-shadow: none;
  }
  .blog_right_sidebar .search_widget .input-group .btn-default {
    position: absolute;
    right: 20px;
    background: transparent;
    border: 0px;
    box-shadow: none;
    font-size: 14px;
    color: #fff;
    padding: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .blog_right_sidebar .author_widget {
    text-align: center;
  }
  .blog_right_sidebar .author_widget h4 {
    font-size: 18px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  .blog_right_sidebar .author_widget p {
    margin-bottom: 0px;
  }
  .blog_right_sidebar .author_widget .social_icon {
    padding: 7px 0px 15px;
  }
  .blog_right_sidebar .author_widget .social_icon a {
    font-size: 14px;
    color: #222222;
    transition: all 0.2s linear;
  }
  .blog_right_sidebar .author_widget .social_icon a + a {
    margin-left: 20px;
  }
  .blog_right_sidebar .author_widget .social_icon a:hover {
    color: #2e3192;
  }
  .blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px;
  }
  .blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 4px;
    transition: all 0.3s linear;
  }
  .blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
    color: #2e3192;
  }
  .blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 0px;
  }
  .blog_right_sidebar .popular_post_widget .post_item + .post_item {
    margin-top: 20px;
  }
  .blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 2px dotted #eee;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px;
  }
  .blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #777;
  }
  .blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px;
  }
  .blog_right_sidebar .post_category_widget .cat-list li + li {
    padding-top: 15px;
  }
  .blog_right_sidebar .post_category_widget .cat-list li:hover {
    border-color: #2e3192;
  }
  .blog_right_sidebar .post_category_widget .cat-list li:hover a {
    color: #2e3192;
  }
  .blog_right_sidebar .newsletter_widget {
    text-align: center;
  }
  .blog_right_sidebar .newsletter_widget .form-group {
    margin-bottom: 8px;
  }
  .blog_right_sidebar .newsletter_widget .input-group-prepend {
    margin-right: -1px;
  }
  .blog_right_sidebar .newsletter_widget .input-group-text {
    background: #fff;
    border-radius: 0px;
    vertical-align: top;
    font-size: 12px;
    line-height: 36px;
    padding: 0px 0px 0px 15px;
    border: 1px solid #eeeeee;
    border-right: 0px;
  }
  .blog_right_sidebar .newsletter_widget .form-control {
    font-size: 12px;
    line-height: 24px;
    color: #cccccc;
    border: 1px solid #eeeeee;
    border-left: 0px;
    border-radius: 0px;
  }
  .blog_right_sidebar .newsletter_widget .form-control.placeholder {
    color: #cccccc;
  }
  .blog_right_sidebar .newsletter_widget .form-control:-moz-placeholder {
    color: #cccccc;
  }
  .blog_right_sidebar .newsletter_widget .form-control::-moz-placeholder {
    color: #cccccc;
  }
  .blog_right_sidebar .newsletter_widget .form-control::-webkit-input-placeholder {
    color: #cccccc;
  }
  .blog_right_sidebar .newsletter_widget .form-control:focus {
    outline: none;
    box-shadow: none;
  }
  .blog_right_sidebar .newsletter_widget .bbtns {
    background: #2e3192;
    color: #fff;
    font-size: 12px;
    line-height: 38px;
    display: inline-block;
    font-weight: 500;
    padding: 0px 24px 0px 24px;
    border-radius: 0;
  }
  .blog_right_sidebar .newsletter_widget .text-bottom {
    font-size: 12px;
  }
  .blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eeeeee;
    margin: 30px 0px;
  }

  .blog-pagination {
    padding-top: 25px;
    padding-bottom: 95px;
  }
  .blog-pagination .page-link {
    border-radius: 0;
  }
  .blog-pagination .page-item {
    border: none;
  }

  .page-link {
    background: transparent;
    font-weight: 400;
  }

  .blog-pagination .page-item.active .page-link {
    color: #fff;
  }

  .blog-pagination .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #8a8a8a;
    border: none;
  }

  .blog-pagination .page-link .lnr {
    font-weight: 600;
  }

  .blog-pagination .page-item:last-child .page-link,
  .blog-pagination .page-item:first-child .page-link {
    border-radius: 0;
  }

  .blog-pagination .page-link:hover {
    color: #fff;
    text-decoration: none;
    background-color: #2e3192;
    border-color: #eee;
  }

  /*============ Start Blog Single Styles  =============*/
  .single-post-area .blog_details {
    padding-top: 26px;
  }
  .single-post-area .blog_details p {
    margin-bottom: 10px;
  }
  .single-post-area .quotes {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 24px 35px 24px 30px;
    background-color: white;
    box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
    font-size: 14px;
    line-height: 24px;
    color: #777;
    font-style: italic;
  }
  .single-post-area .arrow {
    position: absolute;
  }
  .single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600;
  }
  .single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8);
  }
  @media (max-width: 991px) {
    .single-post-area .sidebar-widgets {
      padding-bottom: 0px;
    }
  }

  /*============ End Blog Single Styles  =============*/

  /*------------------------------------------------------------------
    [ input ]*/
  input,
  textarea {
    outline: none;
    border: none;
  }
  input:focus::-webkit-input-placeholder,
  input:focus:-moz-placeholder,
  input:focus::-moz-placeholder,
  input:focus:-ms-input-placeholder,
  textarea:focus::-webkit-input-placeholder,
  textarea:focus:-moz-placeholder,
  textarea:focus::-moz-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: transparent;
  }
  input::-webkit-input-placeholder,
  input:-moz-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea:-moz-placeholder,
  textarea::-moz-placeholder,
  textarea:-ms-input-placeholder {
    color: #cccccc;
  }

  .input-box {
    width: 100%;
    background-color: transparent;
    border: 1px solid #2e3192;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
  }

  .select-box select {
    width: 100%;
    padding: 0 45px 0 20px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #2e3192;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .select-box select:focus {
    outline: none;
  }
  .select_arrow {
    position: absolute;
    top: 16px;
    right: 25px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
  }

  /*------------------------------------------------------------------
    [ hover ]*/
  .c-pointer {
    cursor: pointer;
  }
  .hov1:hover {
    filter: brightness(1.1);
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    cursor: pointer;
  }
  .hov2:hover {
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    color: #2e3192;
    cursor: pointer;
  }
  .hov3:hover {
    filter: drop-shadow(8px 8px 10px gray);
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    color: #2e3192;
    cursor: pointer;
  }
  .hov-bg1:hover,
  .hov-bg1 a:hover {
    color: #fff;
    background: -webkit-linear-gradient(-145deg, #24a791, #2e3192);
    background: -o-linear-gradient(-145deg, #24a791, #2e3192);
    background: -moz-linear-gradient(-145deg, #24a791, #2e3192);
    background: linear-gradient(-145deg, #24a791, #2e3192);
    cursor: pointer;
  }
  .hov-bg2:hover {
    color: #fff;
    background-color: #ffb82a;
    cursor: pointer;
  }

  @media (max-width: 767.98px) {
    .hov1:hover,
    .hov2:hover,
    .hov3:hover {
      filter: none;
      transition: none;
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      color: unset;
    }
  }

  /*------------------------------------------------------------------
    [ background ]*/
  .bg-green {
    background-color: #2e3192;
    color: #fff;
  }

  .bg-red {
    background-color: #ff3b3b;
    color: #fff;
  }

  .bg-image1 {
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
      url("https://images.pexels.com/photos/1204649/pexels-photo-1204649.jpeg?cs=srgb&dl=brainstorming-collaborate-collaboration-1204649.jpg&fm=jpg")
        no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .bg-black-op {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .bg-blackslim-op {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .bg-green-op {
    background-color: rgba(34, 157, 136, 0.1);
  }

  /*------------------------------------------------------------------
    [ color ]*/
  .text-green {
    color: #2e3192;
  }

  /*------------------------------------------------------------------
    [ radio ]*/
  /*------------------------------------------------------------------
    [ dropzone ]*/
  .dropzone {
    min-height: 0;
    padding: 10px;
  }

  #DropZoneFiddle {
    border: 3px dashed #aaaaaa;
  }

  .dz-error-message {
    margin-top: 20px;
  }

  /*------------------------------------------------------------------
    [ grecaptcha ]*/
  .grecaptcha-badge {
    display: none;
  }
}
